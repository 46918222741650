import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import '../styles/addevent.css';

function GruposAdd(){
    const navigate = useNavigate();
    const [Nombres, setNombres] = useState('');
    const [IDCarrera, setIdCarrera] = useState('');
    const [NuevaCarrera, setNuevaCarrera] = useState('');
    const [carreras, setCarreras] = useState([]);
    const [mostrarInput, setMostrarInput] = useState(false);

    useEffect(() => {
      fetchCarreras();
    }, []);

    const fetchCarreras = () => {
      fetch('https://agendautd-back.onrender.com/Carreras')
        .then(response => response.json())
        .then(data => setCarreras(data))
        .catch(error => console.error('Error al cargar las carreras:', error));
    };

      const handleSubmit = (event) => {
        event.preventDefault();
        const Data={
            Nombres,
            IDCarrera
        };

        fetch('https://agendautd-back.onrender.com/gruposadd', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(Data)
    })
      .then(response => response.json())
      .then(data => {
        console.log('grupo creado:', data);
        navigate('/CarrerasList');
      })
      .catch(error => console.error('Error al crear el grupo:', error));
  
      }

      function handleAddCarrera() {
        setMostrarInput(true);
      }
      function agregarNuevaCarrera() {
        if (!NuevaCarrera.trim()) {
          alert("Por favor ingrese el nombre de la nueva carrera.");
          return;
        }
        const nuevaCarreraData = {
          Nombre: NuevaCarrera
        };
        fetch('https://agendautd-back.onrender.com/addCarrera', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(nuevaCarreraData)
        })
          .then(response => response.json())
          .then(data => {
            console.log('Nueva carrera agregada:', data);
            setCarreras([...carreras, data]);
            setNuevaCarrera('');
            setMostrarInput(false);
            fetchCarreras(); // Actualizar la lista de carreras después de agregar una nueva
          })
          .catch(error => console.error('Error al agregar la nueva carrera:', error));
      }
      function Volver() {
        navigate("/CarrerasList");
      }

 return (
    <div className="form-container">
      <form>
        <label>
          Nombre del grupo:
          <input type="text" value={Nombres} onChange={(e) => setNombres(e.target.value)} required />
        </label>
        <label>
          <select value={IDCarrera} onChange={(e) => setIdCarrera(e.target.value)} required>
            <option value="">Seleccionar Carrera</option>
            {carreras.map(carrera => (
              <option key={carrera.ID} value={carrera.ID}>
                {carrera.Nombre}
              </option>
            ))}
          </select>
        </label>
        
        {mostrarInput && (
        <label>
          Nombre de la nueva carrera:
          <input type="text" value={NuevaCarrera} onChange={(e) => setNuevaCarrera(e.target.value)} />
          <button type="button" onClick={agregarNuevaCarrera}>Agregar nueva carrera</button>
        </label>
      )}
      <button onClick={Volver} className="back-button">Regresar</button>
      {!mostrarInput && <button type="button" onClick={handleAddCarrera}>Agregar carrera</button>}

        <button onClick={handleSubmit}>Enviar</button>
        </form>
    </div>
  );
}
export default GruposAdd