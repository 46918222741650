import {BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css';
//rutas
import Home from './pages/Home';
//eventos rutas
import EventInfo from './components/Eventinfo';
import AddEvent from './components/AddEvent';
import AddConferencista from './components/AddConferencista';
import EventListAll from './components/EventListAll';
//usuarios rutas
import  Login  from './pages/Login';
import Userlist from './components/UsuariosList';
import UsuarioUpdate from './components/UsuarioUpdate';

import GruposAdd from './components/GruposAdd';
import CarrerasList from './components/CarrerasList';
import Maestros from './components/Maestros';

function App() {
  return (

    <Router>
      <Routes>
        <Route path="/" element={<Login />} />,
        <Route path="/home" element={<Home />}  />,
        <Route path='/addevent' element={<AddEvent/>}/>,
        <Route path='/addconferencista' element={<AddConferencista/>}/>,
        <Route path="/eventinfo/:eventoId" element={<EventInfo />} />
        <Route path='/usuarios' element={<Userlist/>}/>
        <Route path='/Maestros' element={<Maestros/>}/>
        {/* <Route path='/MaestrosAdd' element={<MaestrosAdd/>}/> */}
        <Route path='/GruposAdd' element={<GruposAdd/>}/>
        <Route path='/CarrerasList' element={<CarrerasList/>}/>
        <Route path='/allevents' element={<EventListAll/>}/>
        <Route path='/updateuser/:userId' element={<UsuarioUpdate/>}/>

      </Routes>
    </Router>
    
  );
}

export default App;
