import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/allevents.css'

function EventListAll() {
    const [eventos, setEventos] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetch('https://agendautd-back.onrender.com/alleventos')
            .then(response => response.json())
            .then(data => setEventos(data))
            .catch(error => console.error('Error al obtener eventos:', error));
    }, []);


    function Eventdelete(eventoId) {
        fetch(`https://agendautd-back.onrender.com/eventdelete/${eventoId}`, {
          method: 'DELETE'
        })
        .then(response => {
          if (response.ok) {
            // Eliminación exitosa, actualiza la lista de usuarios
            setEventos(eventos.filter(evento => evento.ID !== eventoId));
          } else {
            console.error('Error al eliminar el usuario');
          }
        })
        .catch(error => console.error('Error al eliminar el usuario:', error));
    }

    const handleClick = (eventoId) => {
        navigate(`/eventinfo/${eventoId}`);
    };

    function Volver(){
        navigate('/home')
    }

    return (
        <div>
            <h1 className='h1'>Listado de Eventos</h1>
            <div className="allevent-container">
                {eventos.map(evento => (
                    <div key={evento.ID} className="allevent-item">
                        <h2>{evento.Nombre}</h2>
                        <p>{evento.Detalles}</p>
                        <p>Lugar: {evento.Lugar}</p>
                        <p>Fecha de Inicio: {evento.FechaInicio}</p>
                        <button onClick={() => handleClick(evento.ID)} className='button'>Más Información</button>
                        <button onClick={() => Eventdelete(evento.ID)} className="eliminar-btn">Eliminar evento</button>
                    </div>
                ))}
            </div>
            <button onClick={Volver} className="volver-btn">Volver</button>
        </div>
    );    
    
}

export default EventListAll;
