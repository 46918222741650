import { useSession,useSupabaseClient } from '@supabase/auth-helpers-react';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { useNavigate } from 'react-router-dom';
import '../styles/Login.css'   

function Login() {
    const session = useSession();
    const supabase = useSupabaseClient();
    const navigate = useNavigate();

    const handleAuthChange = async (event, session) => {
        if (event === "SIGNED_IN") {
            const { user } = session;
            const { email, user_metadata: { full_name, avatar_url } } = user;
    
            await fetch('https://agendautd-back.onrender.com/AddUser', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, fullName: full_name, photoURL: avatar_url }),
            });
            navigate('/home')
        }
    };

    supabase.auth.onAuthStateChange(handleAuthChange);

    return (
        <div>
            <div className='loginboddy'>
                <Auth
                    supabaseClient={supabase}
                    appearance={{ theme: ThemeSupa }}
                    theme="dark"
                    providers={["google"]}
                    providerScopes={{
                        google:'https://www.googleapis.com/auth/calendar'
                    }}
                />
            </div>
        </div>
    );
}

export default Login
