import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/addevent.css";
import { useSession} from '@supabase/auth-helpers-react';

function AddEvent() {

  const session=useSession();

  const navigate = useNavigate();
  const [nombre, setNombre] = useState('');
  const [detalles, setDetalles] = useState('');
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaCierre, setFechaCierre] = useState('');
  const [idConferencista, setIdConferencista] = useState('');
  const [idMaestro, setIdMaestro] = useState('');
  const [lugar, setLugar] = useState('');
  const [idgrupo, setIdGrupo] = useState('');
  const [idCarrera, setIdCarrera] = useState('');
  const [carreras, setCarreras] = useState([]);
  const [conferencistas, setConferencistas] = useState([]);
  const [maestros, setMaestros] = useState([]);
  const [grupos, setGrupos] = useState([]);

  useEffect(() => {
    fetch('https://agendautd-back.onrender.com/conferencistas')
      .then(response => response.json())
      .then(data => setConferencistas(data))
      .catch(error => console.error('Error al cargar los conferencistas:', error));
  }, []);

  useEffect(() => {
    fetch('https://agendautd-back.onrender.com/maestros')
      .then(response => response.json())
      .then(data => setMaestros(data))
      .catch(error => console.error('Error al cargar a los maestros:', error));
  }, []);

  useEffect(() => {
    fetch('https://agendautd-back.onrender.com/Carreras')
      .then(response => response.json())
      .then(data => setCarreras(data))
      .catch(error => console.error('Error al cargar las carreras:', error));
  }, []);

  useEffect(() => {
    if (idCarrera) {
        fetch(`https://agendautd-back.onrender.com/GruposFiltrados?idCarrera=${idCarrera}`)
        .then(response => response.json())
        .then(data => setGrupos(data))
        .catch(error => console.error('Error al cargar los grupos:', error));
    } else {
      setGrupos([]);
    }
  }, [idCarrera]); 

  const handleSubmit = (event) => {
    event.preventDefault();

    const inicio = new Date(fechaInicio);
    const cierre = new Date(fechaCierre);
  
    // Verificar si la fecha de cierre es posterior a la fecha de inicio
    if (cierre <= inicio) {
      alert('La fecha de cierre debe ser posterior a la fecha de inicio');
      return;
    }

    const eventData = {
      nombre,
      detalles,
      fechaInicio,
      fechaCierre,
      idConferencista,
      idMaestro,
      lugar,
      idgrupo,
      idCarrera
    };

    fetch('https://agendautd-back.onrender.com/addevent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(eventData)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Evento creado:', data);
        navigate('/home');
      })
      .catch(error => console.error('Error al crear el evento:', error));
  };

  function addConferencista() {
    navigate("/addconferencista");
  }

  function Volver() {
    navigate("/home");
  }

  return (
    <div className="form-container">
      <h1>Agrega un evento</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Nombre del Evento:
          <input type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} required />
        </label>

        <label>
          Detalles del evento:
          <input type='text' value={detalles} onChange={(e) => setDetalles(e.target.value)} required />
        </label>

        <label>
          Fecha de Inicio:
          <input type="datetime-local" value={fechaInicio} onChange={(e) => setFechaInicio(e.target.value)} required />
        </label>

        <label>
          Fecha de Cierre:
          <input type="datetime-local" value={fechaCierre} onChange={(e) => setFechaCierre(e.target.value)} required />
        </label>

        <label>
          <select value={idConferencista} onChange={(e) => setIdConferencista(e.target.value)} required>
            <option value="">Seleccionar conferencista</option>
            {conferencistas.map(conferencista => (
              <option key={conferencista.ID} value={conferencista.ID}>
                {conferencista.Nombre}
              </option>
            ))}
          </select>
          <button type="button" onClick={addConferencista}>Agregar conferencista</button>
        </label>

        <label>
          <select value={idMaestro} onChange={(e) => setIdMaestro(e.target.value)} required>
            <option value="">Seleccionar maestro</option>
            {maestros.map(maestro => (
              <option key={maestro.ID} value={maestro.ID}>
                {maestro.Nombres}
              </option>
            ))}
          </select>
        </label>

        <label>
          Lugar:
          <input type="text" value={lugar} onChange={(e) => setLugar(e.target.value)} required />
        </label>

        <label>
          <select value={idCarrera} onChange={(e) => setIdCarrera(e.target.value)} required>
            <option value="">Seleccionar carrera</option>
            {carreras.map(carrera => (
              <option key={carrera.ID} value={carrera.ID}>
                {carrera.Nombre}
              </option>
            ))}
          </select>
        </label>

        <label>
  <select value={idgrupo} onChange={(e) => setIdGrupo(e.target.value)} required>
    <option value="">Seleccionar grupo</option>
    {grupos.map(grupo => (
      <option key={grupo.ID} value={grupo.ID}>
        {grupo.Nombre}
      </option>
    ))}
  </select>
</label>

        <button type="submit">Agregar Evento</button>
      </form>
      <button className="back-button" onClick={Volver}>Volver a menu</button>
      <p></p>
    </div>
  );
}

export default AddEvent;
