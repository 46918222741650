import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Usuario.css";

function Userlist() {
  const [usuarios, setUsuarios] = useState([]);
  const [filtroRol, setFiltroRol] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://agendautd-back.onrender.com/Users')
      .then(response => response.json())
      .then(data => setUsuarios(data))
      .catch(error => console.error('Error al obtener los usuarios:', error));
  }, []);

  function handleDelete(userId) {
    fetch(`https://agendautd-back.onrender.com/deleteUser/${userId}`, {
      method: 'DELETE'
    })
    .then(response => {
      if (response.ok) {
        // Eliminación exitosa, actualiza la lista de usuarios
        setUsuarios(usuarios.filter(usuario => usuario.ID !== userId));
      } else {
        console.error('Error al eliminar el usuario');
      }
    })
    .catch(error => console.error('Error al eliminar el usuario:', error));
  }

  function Volver(){
    navigate('/home')
  }

  const Editar = (userId) =>{
    navigate(`/updateuser/${userId}`);
  }

  function filtrarPorRol(rol) {
    setFiltroRol(rol);
  }

  const usuariosFiltrados = filtroRol ? usuarios.filter(usuario => usuario.Rol === filtroRol) : usuarios;

  return (
    <div>
      
      <div>
        <button className="volver" onClick={Volver}>Volver al menú</button>
        
        <label className="filtro" htmlFor="rol">Filtrar por Rol:
        <select id="rol" value={filtroRol} onChange={(e) => filtrarPorRol(e.target.value)}>
          <option value="">Todos</option>
          <option value="Maestro">Maestro</option>
          <option value="Alumno">Alumno</option>
          <option value="Admin">Admin</option>
        </select>
        </label>
      </div>
      <table className="user-table">
        <thead>
          <tr>
            <th>Foto</th>
            <th>Nombre</th>
            <th>Correo</th>
            <th>Rol</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {usuariosFiltrados.map(usuario => (
            <tr key={usuario.ID}>
              <td><img src={usuario.Foto} alt="Foto de perfil" /></td>
              <td>{usuario.Nombres}</td>
              <td>{usuario.Correo}</td>
              <td>{usuario.Rol}</td>
              <td>
                <button className="delete" onClick={() => handleDelete(usuario.ID)}>Borrar</button>
                <button className="Modificar" onClick={() => Editar(usuario.ID)}>Editar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Userlist;
