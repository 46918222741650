import { createClient } from '@supabase/supabase-js';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import EventList from '../components/EventsLits';
import utd from '../img/utd.png'
import '../styles/menu.css'

const supabase = createClient(
    "https://ujfcwwkzoojujakqwlfp.supabase.co",
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVqZmN3d2t6b29qdWpha3F3bGZwIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTMzODMzNzUsImV4cCI6MjAyODk1OTM3NX0.1SE2fEgvtmLJeu3gcrlru6O5lXe5h0ai03j23QBi19U"
  )

function Home() {
    const [user, setUser] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        async function getUserData() {
            try {
                const response = await supabase.auth.getUser();
                if (response.data?.user) {
                    const userData = await fetchUserData(response.data.user.email);
                    setUser(userData);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        }
        getUserData();
    }, []);

    async function fetchUserData(email) {
        try {
            const response = await fetch(`https://agendautd-back.onrender.com/getUserData?email=${email}`);
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching user data:', error);
            return {};
        }
    }
//botones que redireccionan

    function Allevents(){
        navigate('/allevents')
    }

    function AgregarEvento(){
        navigate(`/addevent`);
    }

    function Usuarios(){
        navigate('/usuarios');
    }

    function Maestros(){
        navigate('/Maestros');
    }

    function Carreras(){
        navigate('/CarrerasList')
    }
    
    
    async function signOutUser() {
        const { error } = await supabase.auth.signOut();
        if (!error) {
            navigate("/");
        } else {
            console.error('Error signing out:', error.message);
        }
    }

    return (
        <div>
            {/* Encabezado */}
          <div className="menu-header">
              <div className="header-content">
                  <h2 className="menu-title">Gestor de Eventos UTD</h2>
                  <img src={utd} alt="Logo" className="menu-logo" />
              </div>
          </div>
      
            {/* Menú */}
            <div className="menu-container">
              <div className="profile-section">
                  <button className='profile-button'>
                          <img src={user.foto} alt={user.ID} className="profile-image" />
                      <div className="profile-info">
                          <h2>{user.fullName}</h2>
                      </div>
                  </button>
              </div>
              <div className="menu-options">
                  {user.role === "Admin" && (
                      <>
                          <button className="menu-option" onClick={AgregarEvento}>
                              <span role="img" aria-label="Crear evento">📅</span> Crear un evento
                          </button>
                          <button className='menu-option' onClick={Allevents}>Lista de eventos</button>
                          <button className="menu-option" onClick={Usuarios}>Usuarios</button>
                          <button className="menu-option" onClick={Maestros}>Maestro</button>
                          <button className="menu-option" onClick={Carreras}>Carreras</button>

                      </>
                  )}
                  {user.role === "Maestro" && (
                        <>
                          <button className="menu-option" onClick={AgregarEvento}>
                              <span role="img" aria-label="Crear evento">📅</span> Crear un evento
                          </button>
                          
                          <button className='menu-option' onClick={Allevents}>Lista de eventos</button>
                        </>
                  )}
                  {user.role === "Alumno" && (
                      <div>
                      </div>
                  )}
              </div>
              <button onClick={signOutUser} className="logout-button">Cerrar sesión</button>
          </div>

          {/* Eventos */}
          <div className='event-list-container'>
            <EventList/>
          </div>
        </div>
      );
      
}

export default Home;
